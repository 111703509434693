<template>
  <v-dialog v-model="dialog" width="650" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab x-small depressed color="success" v-bind="attrs" v-on="on">
        <v-icon size="18">
          {{ studentData.inscription_comment ? "mdi-comment-text" : "mdi-comment" }}
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-form v-model="formIsValid" ref="form" @submit.prevent="updateComment">
        <v-card-title class="text-h5">
          <v-row class="align-center">
            <v-col>
              Comentario {{ studentData.student_name }} {{ studentData.student_surname }}
            </v-col>
            <v-col cols="auto">
              <v-btn fab small depressed @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-textarea
            v-model="comment"
            label="Comentario"
            outlined
            counter
            :rules="[$data.$globalRules.required]"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="submit" :loading="updatingComment">Guardar comentario</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    studentData: { type: Object, required: true },
  },
  data: () => ({
    dialog: null,
    comment: null,
    formIsValid: null,
    updatingComment: null,
  }),
  methods: {
    async updateComment() {
      this.$refs.form.validate();
      if (this.formIsValid) {
        try {
          this.updatingComment = true;
          const payload = {
            inscription_id: this.studentData.inscription_id,
            comment: this.comment,
          };
          await this.$store.state.currentService.updateStudentComment(payload);
          this.$emit("getSessionData");
          this.$notifySuccess(undefined, "Comentario guardado.");
          this.dialog = false;
        } catch (e) {
          this.$notifyError(undefined, "Error al guardar el comentario | " + e);
        } finally {
          this.updatingComment = false;
        }
      }
    },
  },
  watch: {
    dialog(opened) {
      if (opened) {
        this.comment = this.studentData.inscription_comment || "";
      }
    },
  },
};
</script>

<style scoped></style>
